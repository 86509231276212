html, body { width: 100%; height: 100%;}
/* body, .fullpage { margin: 0; padding: 0;} */
body, .fullpage, section {
	margin: 0; padding: 0;
}
a:hover {
	text-decoration: none;
	color: #000;
	opacity: 0.9;
}
.connected, .companyFields {
	display: none;
}
div.jumbotron.carousel-hero {
	padding-top: 20vh;
	padding-bottom: 20vh;
	min-height: 80vh;
}
.login-page {
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	z-index: 1000;
}
.page {
	padding-top: 5rem;
	padding-bottom: 5rem;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
header .masthead {
	background-image:url('../img/home-bg.jpg');
}
footer, section, .shadow-line {
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}
.modal {
	z-index: 10050;
}
.rounded {
	-moz-border-radius: 6px;
	border-radius: 6px;	
}
.rounded16 {
	-moz-border-radius: 16px;
	border-radius: 16px;
}
.shadow {
	box-shadow: 0 0 6px #000;
	-moz-box-shadow: 0 0 6px #000;
	-webkit-box-shadow: 0 0 6px #000;
}
.cursor {
	cursor: pointer;
}
.cursor-zoom {
	cursor: zoom-in;
}
.text-shadow-dark {
	text-shadow: 2px 2px #333;
}
.text-shadow-light {
	text-shadow: 2px 2px #fff;
}
.h1-logo {
	width: 50px;
}
@media (min-width:768px) {
	.h1-logo {
		width: 80px;
	}
}
#mainNav {
	z-index: 9999;
}
@media (max-width:991px) {
	#mainNav {
		z-index: 9999;
		position: fixed;
		width: 100%;
		opacity: 0.9;
	}
}
@media (min-width:992px) {
	#mainNav {
		border-bottom: 1px solid #fff;
		background-color: hsla(0,0%,100%,.9);
	}
}
#mainNav .navbar-brand {
	color: #333;
}
#mainNav a.navbar-brand:hover {
	color: #33b7cc;
}
#mainNav .navbar-nav > li.nav-item > a {
	color: #212529;
}
#mainNav .navbar-nav > li.nav-item > a:hover {
	color: #33b7cc;
}
#mainNav .navbar-nav > li.nav-item > a.active {
	color: #009FE3;
}
#mainNav.is-fixed {
	top: -100px;
}
.navbar-brand {
	display: inline-block;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	margin-right: 1rem;
	font-size: 1.5rem;
	line-height: inherit;
	white-space: nowrap;
}
#mainNav .navbar-nav > li.nav-item > a {
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 1px;
	text-transform: uppercase;
}
#mainNav.is-fixed .navbar-nav > li.nav-item > a {
	color: #212529;
}
#mainNav.is-fixed .navbar-nav > li.nav-item > a:hover {
	color: #33b7cc;
}
#mainNav.is-fixed .navbar-nav > li.nav-item > a.active {
	color: #17a2b8;
}
#mainNav .navbar-nav > li.nav-item > a.active {
	color: #33b7cc;
}
/* simplelightbox css fix so that close button is above navbar */
.sl-wrapper .sl-close {
	z-index: 10060;
}
header.masthead .page-heading, header.masthead .post-heading, header.masthead .site-heading {
	padding: 100px 0;
	color: #fff;
}
@media (min-width:768px) {
	header.masthead .page-heading, header.masthead .post-heading, header.masthead .site-heading {
		padding: 200px 0;
	}
}
.navbar .nav-item .nav-link .img-profile {
	height: 2rem;
	width: 2rem;
}
.nav-align-img-profile {
	padding-top: 0.4rem;
}
h2.post-title {
	color: #33b7cc;
}
.dropzone {
	border: 2px dashed #33b7cc;
	border-radius: 5px;
	background: white;
}
.dz-details {
	z-index: 3000;
}
footer a:hover {
	color: #33b7cc;
}
footer a:active, footer a:focus {
	color: #f6c23e;
}
a.go-up-fixed {
	display: none;
	position: fixed;
	bottom: 0px;
	right: 0px;
	border:none;
	border-radius:50%;
	margin:4px;
	color:#fff;
	text-align:center;
	background-color:#33b7cc;
	opacity:0.8;
	z-index: 900;
}
a.go-up-fixed:hover {
	opacity:1;
}
.my-logo {
	width: 64%;
	max-width: 1920px;
}
.f-right {
	float: right;
}
@media (max-width:768px) {
	.my-logo {
		width: 68%;
	}
}
.required-input {
	color: red;
}
.dropdown .dropdown-list .dropdown-header {
    background-color: #f6c23e;
    border: 1px solid #f6c23e;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #121212;
}
#successfail {
	margin-top: 1rem;
}
*:disabled  {
	cursor: not-allowed;
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
    display: flex;
    justify-content: center;
}
.align-items-center-right {
    -ms-flex-align: center!important;
    align-items: center!important;
    display: flex;
    justify-content: right;
}
.loading {
	padding: 6rem;
}
#mainNav .navbar-nav > li.nav-item > a:focus {
	color: rgba(0, 0, 0, 0.8);
}
.table td, .table th {
	vertical-align: middle;
}
.table-cont-scroll {
	overflow-x: auto;
}
.tablesorter-bootstrap {
    font: 11px/18px Arial,Sans-serif;
    text-align: left;
    border-spacing: 0;
}
.tablesorter input[type=search] {
	font-size: 16px;
	color: #333;
	padding: 6px;
	margin: 2px;
	-moz-box-shadow:inset 2px 2px 5px #ccc;
	box-shadow:inset 2px 2px 5px #ccc;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #b8b8b8;
}
.pager select {
	font-size: 16px;
	color: #333;
	padding: 6px;
	margin: 2px;
	-moz-box-shadow:inset 2px 2px 5px #ccc;
	box-shadow:inset 2px 2px 5px #ccc;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: 1px solid #b8b8b8;
}
.bg-gradient-primary-to-secondary {
	background-color: #1da1f5;
	background-image: linear-gradient(135deg, #1da1f5 0%, rgba(105, 0, 199, 0.8) 100%);
}
.bg-gradient-secondary-to-warning {
	background-color: #6900c7;
	background-image: linear-gradient(135deg, #6900c7 0%, #ffc147 100%);
}
.bg-gradient-indigo-to-orange {
	background-color: #5800e8;
	background-image: linear-gradient(135deg, #5800e8 0%, #f76400 100%);
}
.bg-gradient-light-to-info {
	background-color: #eff3f9;
	background-image: linear-gradient(135deg, #eff3f9 0%, #2ccfd4 100%);
}
.bg-gradient-primary{background-color:#1da1f5;background-image:linear-gradient(180deg,#1da1f5 10%,#36b9cc);background-size:cover}
.bg-gradient-secondary{background-color:#858796;background-image:linear-gradient(180deg,#858796 10%,#60616f);background-size:cover}
.bg-gradient-success{background-color:#1cc88a;background-image:linear-gradient(180deg,#1cc88a 10%,#13855c);background-size:cover}
.bg-gradient-info{background-color:#36b9cc;background-image:linear-gradient(180deg,#36b9cc 10%,#258391);background-size:cover}
.bg-gradient-warning{background-color:#f6c23e;background-image:linear-gradient(180deg,#f6c23e 10%,#dda20a);background-size:cover}
.bg-gradient-danger{background-color:#e74a3b;background-image:linear-gradient(180deg,#e74a3b 10%,#be2617);background-size:cover}
.bg-gradient-light{background-color:#f8f9fc;background-image:linear-gradient(180deg,#f8f9fc 10%,#c2cbe5);background-size:cover}
.bg-gradient-dark{background-color:#5a5c69;background-image:linear-gradient(180deg,#5a5c69 10%,#373840);background-size:cover}
/* Zoom-in and other effects on hover */
/* [1] The container */
.img-hover-zoom {
	/* height: 300px; [1.1] Set it as per your need */
	overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}
/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
	transition: transform .5s ease;
}
/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
	transform: scale(1.5);
}
/* Quick-zoom Container */
.img-hover-zoom--quick-zoom img {
	transform-origin: 0 0;
	transition: transform .25s, visibility .25s ease-in;
}
/* The Transformation */
.img-hover-zoom--quick-zoom:hover img {
	transform: scale(2);
}
/* Point-zoom Container */
.img-hover-zoom--point-zoom img {
	transform-origin: 65% 75%;
	transition: transform 1s, filter .5s ease-out;
}
/* The Transformation */
.img-hover-zoom--point-zoom:hover img {
	transform: scale(5);
}
/* Zoom-n-rotate Container */
.img-hover-zoom--zoom-n-rotate img {
	transition: transform .5s ease-in-out;
}
/* The Transformation */
.img-hover-zoom--zoom-n-rotate:hover img {
	transform: scale(2) rotate(25deg);
}
/* Slow-motion Zoom Container */
.img-hover-zoom--slowmo img {
	transform-origin: 50% 65%;
	transition: transform 5s, filter 3s ease-in-out;
	filter: brightness(150%);
}
/* The Transformation */
.img-hover-zoom--slowmo:hover img {
	filter: brightness(100%);
	transform: scale(3);
}
/* Brightness-zoom Container */
.img-hover-zoom--brightness img {
	transition: transform 2s, filter 1.5s ease-in-out;
	transform-origin: center center;
	filter: brightness(80%);
}
/* The Transformation */
.img-hover-zoom--brightness:hover img {
	filter: brightness(100%);
	transform: scale(1.3);
}
/* Brightness-zoom Container */
.img-hover-zoom--brightness-sold-out img {
	transition: transform 2s, filter 1.5s ease-in-out;
	transform-origin: center center;
	filter: brightness(50%);
}
/* The Transformation */
.img-hover-zoom--brightness-sold-out:hover img {
	filter: brightness(100%);
	transform: scale(1.3);
}
/* Horizontal Zoom-n-pan Container */
.img-hover-zoom--zoom-n-pan-h img {
	transition: transform .5s ease-in-out;
	transform: scale(1.4);
	transform-origin: 100% 0;
}
/* The Transformation */
.img-hover-zoom--zoom-n-pan-h:hover img {
	transform: scale(1.5) translateX(30%);
}
/* Vertical Zoom-n-pan Container */
.img-hover-zoom--zoom-n-pan-v img {
	transition: transform .5s ease-in-out;
	transform: scale(1.4);
	transform-origin: 0 0;
}
/* The Transformation */
.img-hover-zoom--zoom-n-pan-v:hover img {
	transform: scale(1.25) translateY(-30%);
}
/* Blur-zoom Container */
.img-hover-zoom--blur img {
	transition: transform 1s, filter 2s ease-in-out;
	filter: blur(2px);
	transform: scale(1.2);
}
/* The Transformation */
.img-hover-zoom--blur:hover img {
	filter: blur(0);
	transform: scale(1);
}
/* Colorize-zoom Container */
.img-hover-zoom--colorize img {
	transition: transform .5s, filter 1.5s ease-in-out;
	filter: grayscale(100%);
}
/* The Transformation */
.img-hover-zoom--colorize:hover img {
	filter: grayscale(0);
	transform: scale(1.1);
}
.clean-block.clean-product .sp-wrap {
	background: transparent;
	border: none;
	float: none;
	max-width: 100%;
}
.btn-group-sm > .btn, .btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
.btn-icon {
	padding: 0;
	-webkit-box-pack: center;
			justify-content: center;
	overflow: hidden;
	border-radius: 100%;
	flex-shrink: 0;
	height: calc( (1rem * 1.5) + (0.5rem * 2) + (2px) ) !important;
	width: calc( (1rem * 1.5) + (0.5rem * 2) + (2px) ) !important;
}
.btn-icon.btn-xl {
	height: calc( (1.25rem * 1.5) + (1.25rem * 2) + (2px) ) !important;
	width: calc( (1.25rem * 1.5) + (1.25rem * 2) + (2px) ) !important;
	border-radius: 100%;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
	height: calc( (1.25rem * 1.5) + (0.5rem * 2) + (2px) ) !important;
	width: calc( (1.25rem * 1.5) + (0.5rem * 2) + (2px) ) !important;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
	height: calc( (0.875rem * 1.5) + (0.25rem * 2) + (2px) ) !important;
	width: calc( (0.875rem * 1.5) + (0.25rem * 2) + (2px) ) !important;
}
.btn-icon.btn-xs {
	height: calc( (0.7rem * 1.5) + (0.25rem * 2) + (2px) ) !important;
	width: calc( (0.7rem * 1.5) + (0.25rem * 2) + (2px) ) !important;
	border-radius: 100%;
}
.btn-icon.btn-link {
	text-decoration: none;
}
.btn-icon .ng-fa-icon {
	display: -webkit-inline-box;
	display: inline-flex;
}
.border-6 {
	border-width: 6px !important;
}
.border-left-6 {
	border-left-width: 6px !important;
}
.border-top-6 {
	border-top-width: 6px !important;
}
.border-right-6 {
	border-right-width: 6px !important;
}
.border-bottom-6 {
	border-bottom-width: 6px !important;
}