div.jumbotron.carousel-hero {
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 0;
}

div.jumbotron.hero-step1 {
  background-image: url('../img/bg_shopping.png');
}
div.jumbotron.hero-step2 {
  background-image: url('../img/bg_code.png');
}
div.jumbotron.hero-step3 {
  background-image: url('../img/bg_test_it.png');
}
div.jumbotron.hero-step4 {
  background-image: url('../img/bg_delivery.png');
}

h1.hero-title, h2.hero-title, h3.hero-title {
  text-shadow: 0 3px 3px rgba(0,0,0,0.4);
  color: #fff;
}

p.hero-subtitle {
  max-width: 600px;
  margin: 25px auto;
  padding: 10px 15px;
  color: #fff;
}

.plat {
  border-radius: 0px;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0 #048f83!important;
}

.btn-primary:hover {
  color: #fff;
  background-color: #016090;
  border-color: #016090;
}

.btn.btn-primary {
  background-color: #048f83!important;
  border-color: #048f83!important;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.5);
}

span {
  font-style: inherit;
  font-weight: inherit;
}

